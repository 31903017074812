import { BrowserRouter, Routes, Route } from "react-router-dom";
import Component from "./components/Component";
import SignIn from "./components/SignIn";
import GoldenTriangleOfNepal from "./components/PackagesFolder/GoldenTriangleOfNepal";
import GloryOfKnP from "./components/PackagesFolder/GloryOfKnP";
import GlimpsesOfKathmandu from "./components/PackagesFolder/GlimpsesOfKathmandu";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Component />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/GloryOfKnP" element={<GloryOfKnP />} />
          <Route
            path="/GoldenTriangleOfNepal"
            element={<GoldenTriangleOfNepal />}
          />
          <Route
            path="/GlimpsesOfKathmandu"
            element={<GlimpsesOfKathmandu />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

import React from "react";
import styled from "styled-components";

const GloryOfKnP = () => {
  return (
    <ContainerPac>
      <DItinerary>
        <h1 style={{ color: "gold" }}> Glory of Kathmandu & Pokhara</h1>
      </DItinerary>
      <DayContainer>
        <h2>Trip Introduction</h2>
        This tour is all about the capital city of Nepal, Kathmandu valley, its
        people and culture, monuments, narrow local streets, local foods and
        many more. Kathmandu valley has 3 different cities named Kathmandu city,
        Patan city and Bhaktapur city. You can enjoy the Durbar Squares, Holy
        Temples, Stupas and shopping of Nepalese arts and artifacts. This
        wonderful tour is surrounded the natural beauty, cultural and
        cosmopolitan aspects. Another second best destination called Pokhara,
        the nature’s hidden paradise possesses nature’s gift of extreme beauty
        with majestic view of Annapurna range at its backdrop and tranquility of
        lakes within it.
        <ShortItinerary>
          <h2>Outline Itinerary</h2>
          <DaysOfVisit>Day 01:</DaysOfVisit> Arrival in Kathmandu. Meet, assist
          and transfer to Hotel. Overnight stay at Hotel.
          <DaysOfVisit>Day 02:</DaysOfVisit> Full-day sightseeing tour of
          Kathmandu city, Swoyambhunath, Pashupatinath & Boudhanath. Overnight
          stay at Hotel.
          <DaysOfVisit>Day 03:</DaysOfVisit> Drive early morning to Pokhara
          (Approx 6 hrs) and transfer to Hotel. Rest of the day for relax or
          walk around the lake side. Overnight stay at Hotel
          <DaysOfVisit>Day 04:</DaysOfVisit> Early morning visit to Sarangkot
          and Half-day tour in Pokhara. [Devi’s fall, Gupteswor Mahadev, Seti
          Gorge, Mahendra Gufa (Bat’s cave) Bindebasini temple] Even: Enjoy one
          hour boat ride in Fewa lake.Overnight stay at Hotel.
          <DaysOfVisit>Day 05:</DaysOfVisit>After breakfast drive back to
          Kathmandu and transfer to Hotel. Overnight stay at Hotel.
          <DaysOfVisit>Day 06:</DaysOfVisit>Full-day sightseeing tour of Patan
          City and Bhaktapur city. Overnight stay at Hotel.
          <DaysOfVisit>Day 07:</DaysOfVisit>Transfer to the International
          airport for your departure onward flight.
        </ShortItinerary>
      </DayContainer>
      <DayContainer>
        <DItinerary>
          <h2>Detailed Itinerary</h2>
        </DItinerary>
      </DayContainer>
      <DayContainer>
        <DaysOfVisit>DAY 01: ARRIVE KATHMANDU</DaysOfVisit>
        Welcome to the land of Himalayas! As soon as you arrive in Kathmandu
        International airport, our representative will be there to receive and
        escort you to your hotel. After refreshment, you can take a lazy stroll
        around your hotel or relax at your hotel premises. We make sure that
        your first acquaintance with the capital city is a memorable one!
        Overnight stay at Hotel.
      </DayContainer>
      <DayContainer>
        <DaysOfVisit>DAY 02: KATHMANDU TOUR</DaysOfVisit>
        Today is a big day for you as you finally get to kick-start your
        cultural discovery! Full-day sightseeing tour of Kathmandu city,
        Swoyambhunath, and Patan City. (Description of the tour highlights goes
        here) Overnight stay at hotel.
        <h3>Kathmandu Durbar Square (World Heritage Site)</h3>
        <KSVImage src={require("./PackageAssets/kds.png")} alt="cover" />
        <>
          Kathmandu Durbar Square is an overwhelming frenzy of art and
          architecture. The medieval palace complex is erected in different
          styles right beside the Kumari Temple where Living Kumari Goddess
          lives there. The enormous courtyard is locally known as the Kathmandu
          Durbar Square or Nautale Durbar. The Durbar Square is called the
          Hanumandhoka, the Monkey God’s Door in Nepal, named so after the
          Monkey God Hanuman. The area is also known as Basantpur, the place for
          celebrations and festivals. The UNESCO enlisted the Durbar Square area
          as the World Heritage Site in 1979. The massive pagoda-like structure
          in the center houses the tutelary deity of the Malla Kings. The palace
          building is a maze of stone-paved courtyards. Coronation ceremonies,
          until recently, were performed in the main courtyard, the Nyasal
          Chowk.
        </>
        <h3>Swayambhunath/Monkey Temple (World Heritage Site)</h3>
        <KSVImage
          src={require("./PackageAssets/Swayambhunath.png")}
          alt="cover"
        />
        The Swayambhunath Stupa crowns a hillock to the west of Kathmandu. A
        massive white dome surmounted by a 13-stage spire, the stupa is one of
        the most sacred Buddhist sites in Nepal. It is said to be 2000 years
        old. Its origins are linked to the creation of the Kathmandu Valley by
        Bodhisattva Manjushree. The site of the present-day city was originally
        a huge lake. Swayambhu was manifested in the lake as a brilliant light
        emanating from a lotus, and Manjushree let the water out by slashing a
        passage through the surrounding hills to facilitate paying homage to the
        deity, thus making the valley habitable.
        <h3>Pashupatinath (World Heritage Site)</h3>
        <KSVImage
          src={require("./PackageAssets/Pashupatinath.png")}
          alt="cover"
        />
        Pashupatinath, considered one of the holiest shrines of all the Hindu
        temples, has remained the presiding deity of ruling Nepalese Royalty.
        Located on the banks of the Bagmati river, this two-tiered magnificent
        golden temple with four triple silver doorways is a unique example of
        Nepalese temple architecture. It is one of the largest Hindu temple
        complexes in South Asia with hundreds of Shiva lingams, shrines, and
        icons of various Hindu gods and goddesses inside.
        <h3>Boudhanath (World Heritage Site)</h3>
        <KSVImage src={require("./PackageAssets/Boudhanath.png")} alt="cover" />
        Boudhanath, one of the oldest and the biggest Buddhist monuments ever
        built in Nepal, is an imposing structure standing some 36 meters. The
        Stupa stands on the massive three-level mandala-style platforms
        surrounded by colorful private family houses. The basic feature of this
        great stupa is very much like that of Swayambhunath stupa except for its
        finial displaying. It is much bigger than Swayambhu stupa and lies on
        the valley floor, whereas the former one stands on the hilltop. This
        stupa is said to have been built in the 5th century A.D. The site is
        considered very much like Mecca for the Tibetan Buddhists, and every
        year tens of thousands of pilgrims from all over the Himalayan region
        visit the stupa.
      </DayContainer>
      <DayContainer>
        <DaysOfVisit>DAY 03: KATHMANDU-POKHARA</DaysOfVisit>
        After breakfast and early morning activities, drive to Pokhara (Approx 6
        hrs) and transfer to Hotel.Overnight in Hotel.
        <h3>POKHARA</h3>
        <KSVImage src={require("./PackageAssets/Pokhara.jpg")} alt="cover" />
        Pokhara, some 200 km to the west of Kathmandu, luxuriates in one of the
        most enchanting natural sceneries in the world. Here you can encounter
        the snow peaks of the Himalaya up close without having to go on a trek.
        With its soothing climate, the balmy atmosphere of a resort town and the
        raw beauty of an exotic, untouched landscape, Pokhara offers an
        unusually blissful break away from the frenzied pace of city life. An
        illustrious member of the Annapurna range, rising out of which are a
        bevy of other equally distinguished summits that protect the valley in a
        breathtaking semi-circle, it levitates to a height of 6,977 m and is one
        of the most enduring natural fixtures of Pokhara. Rest of the day for
        relax or walk around the lake side. Overnight stay at Hotel.
        <DaysOfVisit>DAY 04: POKHARA TOUR</DaysOfVisit>
        Early morning visit to Sarangkot and Half-day tour in Pokhara [Devi’s
        fall, Gupteswor Mahadev, Seti Gorge, Mahendra Gufa (Bat’s cave)
        Bindebasini temple].
        <h3>Sarangkot</h3>
        <KSVImage src={require("./PackageAssets/Sarangkot.jpg")} alt="cover" />
        Sarangkot is the small village which has its own natural beauty. It lies
        in the outskirts of the Pokhara city. From Sarangkot hill you can see
        the best sunrise, beautiful green hills, terraces of rice and the
        beautiful view of Pokhara valley. It is also an important location for
        most of the paragliders.
        <h3>Devi’s Fall</h3>
        <KSVImage
          src={require("./PackageAssets/Devi's Falls.jpg")}
          alt="cover"
        />
        Devi’s Fall is a charming little waterfall located about 2 km south-west
        of the Pokhara airport on the Siddhartha Highway. Its source is the
        water from the Phewa Lake. Devi’s Fall attracts the visitor to view the
        mystery of water fall and its associated painful legend of David’s fall.
        Gupteswar Gupha is a mysteriously sacred cave located very close to
        Devi’s Fall. This cave holds special value for Hindus’ since a phallic
        symbol of Lord Shiva is preserved here in the condition it was
        discovered.
        <h3>Gupteshwar Mahadev Cave </h3>
        <KSVImage
          src={require("./PackageAssets/Gupteshwor.jpeg")}
          alt="cover"
        />
        Gupteshwar Mahadev Cave in Pokhara is the cave where the Devi’s fall
        leads to. Just across the road from Devi’s fall, a spiral staircase
        takes you to the entrance of Gupteswar Mahadev Cave. As you enter the
        cave, there are walls dripping water and the way around the cave is lit
        with a string of lights which often go off, so carrying torches in
        recommended. About 40m in you can see a statue of Lord Shiva which was
        discovered when two locals accidentally came across the cave. The statue
        is considered important and holy according to Hindu’s and witness quite
        a number of devotees on particular days.
        <h3>Seti Gandaki Gorge </h3>
        <KSVImage src={require("./PackageAssets/SetiGorge.jpeg")} alt="cover" />
        Seti Gandaki Gorge, a wonders of nature goes underground, vanishing from
        sight in many places along its route through the city. At various points
        the river is barely two meters wide where its depth beyond imagination.
        A good spot for watching the river below is Mahendra Pul, a small bridge
        at the center of the city.
        <h3>Mahendra Cave </h3>
        <KSVImage
          src={require("./PackageAssets/MahendraCave.jpeg")}
          alt="cover"
        />
        Mahendra Gupha is another major attractive natural site reached after a
        two-hour walk north of Pokhara. The cave is known as the ‘House of Bats’
        owing to a number of bats living within its walls. There is another deep
        cave called Chamere Gufa (Bat’s Cave) owing to a number of bats living
        within its walls.
        <h3>The Bindhyabasini temple </h3>
        <KSVImage
          src={require("./PackageAssets/Bindhyabasini.jpeg")}
          alt="cover"
        />
        The Bindhyabasini temple is the focus of religious activity in the old
        bazaar. It is dedicated to the Hindu goddess Bhagwati, a manifestation
        of Shakti. The park-like grounds offer a fine picnic area, and on
        Saturdays and Tuesdays when devotees flock here to offer sacrifices,
        take on a festive local flavor. The old part of town bears resemblance
        to the traditional urban layout of Kathmandu, with temples and exquisite
        house facades. These architectural styles were brought by settlers from
        the Kathmandu Valley who, centuries ago, were invited by one of the
        kings of Kaski to set up shop and engage in trade. Even: Enjoy one hour
        boat ride in Fewa lake.
        <KSVImage src={require("./PackageAssets/Fewa.jpg")} alt="cover" />
        Boating in Nepal is one of the interesting recreational things. It has
        glacier fed lakes up north to the gently moving rivers in terai, you can
        enjoy the moments with lots of sights near around you. Phewa Lake is the
        second largest lake in Pokhara, the lake bordering the forested hill.
        The view of Fishtail and other mountains reflected in the lake, the
        little island and the Tal Barahi temple in the middle, the white stupa
        on the top of the hill, the gliders jumping from Sarangkot, the
        Ultralight soaring up high and paralleled each other, buzzing, all at
        once can be seized in the mild breeze over the Phewa Lake. Overnight
        stay at Hotel.
        <DaysOfVisit>DAY 05: POKHARA-KATHMANDU</DaysOfVisit>
        After breakfast drive back to Kathmandu and transfer to Hotel. Overnight
        stay at Hotel.
        <DaysOfVisit>DAY 06: KATHMANDU TOUR</DaysOfVisit>
        Full-day sightseeing tour of Patan City and Bhaktapur city.
        <h3>Patan Durbar Square (World Heritage Site)</h3>
        <KSVImage
          src={require("./PackageAssets/Patan Durbar Square.png")}
          alt="cover"
        />
        Most of the monuments in this square belong to the medieval Malla period
        between the 15th and 17th centuries AD. Important things to be seen in
        this area include the Golden Gate and the Golden Window of the old
        palace, the beautiful traditional metal craft, the famed Krishna temple
        with its 21 golden pinnacles, and the Royal Bath of Sundari Chowk, a
        perfect piece of classical stonework. The Royal Taleju temple and the
        Vishwa Narayan temple are fine examples of unique craftsmanship in wood.
        The temple of Bhimsen with its magnificent golden balcony overlooking
        the square outside includes many other shrines and sculptures scattered
        in and around the square.
        <h3>Bhaktapur Durbar Square (World Heritage Site)</h3>
        <KSVImage
          src={require("./PackageAssets/Bhaktapur Durbar Square.png")}
          alt="cover"
        />
        The city of Bhaktapur lies 14 km to the east of Kathmandu. Bhaktapur is
        popular for its religious and historical heritage and is also known as
        the "City of Culture" and "City of Devotees". Its Durbar Square is a
        symphony of art and architecture. The centerpiece is the 55-window
        palace overlooking the square, which is paved over with brick. The
        history of the palace dates back to the 12th century. Bhaktapur, also
        known as Bhadgaon, was the capital of the Kathmandu Valley once during
        medieval times. Today, it is a treasured home of medieval art and
        architecture. According to legend, this city was founded in 889 AD by
        the late King Anand ev, who belonged to the famed Lichhavi dynasty.
        Bhaktapur is the embodiment of the "Living heritage", with Hindus making
        up the majority of the population. Overnight stay at Hotel.
        <DaysOfVisit>DAY 07: DEPART KATHMANDU</DaysOfVisit>
        Our cheerful representative will drop you off at the international
        airport on time prior to your departure flight. The mystic land of the
        Himalayas holds many more secrets to be told. Come back again for
        another adventure visiting our spectacular cultural sites mountains.
      </DayContainer>
    </ContainerPac>
  );

  /*Change HTML Here */
};

export default GloryOfKnP;

const ContainerPac = styled.div`
  width: 90vw;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background: #ecf1f0;
  box-sizing: border-box;

  @media (min-width: 768px) {
    margin-top: 80px;
    margin-bottom: 80px;
  }
`;

const DayContainer = styled.div`
  width: 90vw;
  max-width: 1100px;
  margin: 0 auto;
  padding: 10px;
  background: #ecf1f0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    margin-top: 80px;
    margin-bottom: 80px;
  }
`;

const Header = styled.header`
  text-align: center;
  margin-bottom: 20px;

  h1 {
    font-size: 2.5rem;
    color: #333;
  }
`;

const Section = styled.div`
  margin-bottom: 20px;

  h2 {
    text-align: center;
    font-size: 1.8rem;
    color: #;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 1.5rem;
    color: #333;
    margin-top: 20px;
    margin-bottom: 10px;
  }
`;

const ShortItinerary = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

const DItinerary = styled.div`
  color: blue;
  position: absolute;
  left: 38.5vw;
  margin-bottom: 30px;
`;

const DaysOfVisit = styled.div`
  color: red;
  font-size: 1.8rem;
  display: flex;
  flex-direction: column;
`;

const KSVImage = styled.img`
  width: 50vw;
  height: 50vh;
  object-fit: cover;
`;

import React from "react";
import styled from "styled-components";

function Subscription() {
  return (
    <Container>
      <SubscriptionForm>
        <Title>Speak to an Expert</Title>

        <Form>
          <FormRow>
            <FormItem>
              <Field placeholder="✉️ Your Name" />
            </FormItem>
            <FormItem>
              <Field placeholder="✉️ Your Email" />
            </FormItem>
            <FormItem>
              <Field placeholder="✉️ Message" />
            </FormItem>
          </FormRow>
        </Form>
        <SmallContainer>
          <Ui>
            "If you have any questions or concerns about the trip, feel free to
            contact us."
          </Ui>

          <Ui>WhatsApp Call</Ui>
          <Ui> +9779841744992</Ui>
        </SmallContainer>
      </SubscriptionForm>
      <TopDecor src={require("../assets/send.png")} alt="" />
      <Button style={{ textDecoration: "none" }}>Send Message</Button>
      <Decor src={require("../assets/bottomrightdesign.png")} alt="" />
    </Container>
  );
}

export default Subscription;

const Container = styled.div`
  height: 55vh;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  media (max-width: 480px) {
    margin-top: 100px;
  }

  background: #333;
`;

const SmallContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  media (max-width: 480px) {
    margin-top: 100px;
  }
`;

const SubscriptionForm = styled.div`
  height: 50vh;
  width: 60vw;
  background: #9fffeca0;
  border-radius: 129px 20px 20px 20px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 480px) {
    width: 90%;
    height: 50%;
    justify-content: center;
  }
`;

const Decor = styled.img`
  height: 15vh;
  margin-top: 10vh;
  margin-left: 61vw;
  @media (max-width: 480px) {
    margin-bottom: -100px;
  }
`;

const TopDecor = styled.img`
  position: absolute;
  height: 6vh;
  right: 18.5vw;
  @media (max-width: 480px) {
    margin-top: -110px;
    right: 1.5vw;
  }
`;

const Button = styled.a`
  position: absolute;
  margin-left: 3vw;
  right: 22.5vw;
  background-color: #00a651;
  padding: 1.5vh 2vw;
  border-radius: 7px;
  font-size: 2vh;
  font-weight: 500;
  color: white;
  cursor: pointer;
  @media (max-width: 480px) {
    width: 100%;
    text-align: center;
    margin-left: 0;
    margin-top: 10px;
  }
  &:hover {
    color: white;
  }
`;

const Title = styled.div`
  font-size: 3vh;
  width: 39vw;
  color: #5e6282;
  font-weight: 600;
  @media (max-width: 480px) {
    padding: 0 16px;
    width: 100%;
    text-align: center;
    padding: 16px;
  }
`;

const Form = styled.div`
  @media (max-width: 480px) {
    display: flex;
    width: 70vw;
  }
`;

const Field = styled.input`
  padding: 2vh 2vw;
  border: none;
  outline: none;
  border-radius: 10px;
  font-weight: 400;
  font-size: 13px;
  width: 20vw;
  @media (max-width: 480px) {
    width: 100%;
    height: 100%;
    text-align: center;
  }
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  width: 100%; /* Added to ensure the form items take up the full width */
`;

const FormItem = styled.div`
  margin-bottom: 10px;
`;

const Ui = styled.div`
  margin-bottom: 10px;
`;

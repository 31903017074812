import React from "react";
import styled from "styled-components";

const GlimpsesOfKathmandu = () => {
  return (
    <ContainerPac>
      <DItinerary>
        <h1 style={{ color: "gold" }}> Glimpses of Kathmandu</h1>
      </DItinerary>
      <DayContainer>
        <h2>Trip Introduction</h2>
        This tour is all about the capital city of Nepal, Kathmandu valley, its
        people and culture, monuments, narrow local streets, local foods and
        many more. Kathmandu valley has 3 different cities named Kathmandu city,
        Patan city, and Bhaktapur city. You can enjoy the Durbar Squares, Holy
        Temples, Stupas, and shopping of Nepalese arts and artifacts. This
        wonderful tour is surrounded by natural beauty, cultural, and
        cosmopolitan aspects. Kathmandu imparts the very essence of different
        religious beliefs, rich heritage sites, and cultural diversity.
        <ShortItinerary>
          <h2>Outline Itinerary</h2>
          <DaysOfVisit>Day 01:</DaysOfVisit> Arrival in Kathmandu. Meet, assist
          and transfer to hotel.
          <DaysOfVisit>Day 02:</DaysOfVisit> Full-day sightseeing tour of
          Kathmandu city, Swayambhunath & Patan city.
          <DaysOfVisit>Day 03:</DaysOfVisit> Full-day sightseeing tour of
          Pashupatinath, Boudhanath & Bhaktapur city.
          <DaysOfVisit>Day 04:</DaysOfVisit> Transfer to the International
          airport for your departure onward flight.
        </ShortItinerary>
      </DayContainer>
      <DayContainer>
        <DItinerary>
          <h2>Detailed Itinerary</h2>
        </DItinerary>
      </DayContainer>
      <DayContainer>
        <DaysOfVisit>DAY 01: ARRIVE KATHMANDU</DaysOfVisit>
        Welcome to the land of Himalayas! As soon as you arrive at Kathmandu
        International airport, our representative will be there to receive and
        escort you to your hotel. After refreshment, you can take a lazy stroll
        around your hotel or relax at your hotel premises. We make sure that
        your first acquaintance with the capital city is a memorable one!
        Overnight stay at hotel.
      </DayContainer>
      <DayContainer>
        <DaysOfVisit>DAY 02: KATHMANDU TOUR</DaysOfVisit>
        Today is a big day for you as you finally get to kick-start your
        cultural discovery! Full-day sightseeing tour of Kathmandu city,
        Swoyambhunath, and Patan City. (Description of the tour highlights goes
        here) Overnight stay at hotel.
        <h3>Kathmandu Durbar Square (World Heritage Site)</h3>
        <KSVImage src={require("./PackageAssets/kds.png")} alt="cover" />
        <>
          Kathmandu Durbar Square is an overwhelming frenzy of art and
          architecture. The medieval palace complex is erected in different
          styles right beside the Kumari Temple where Living Kumari Goddess
          lives there. The enormous courtyard is locally known as the Kathmandu
          Durbar Square or Nautale Durbar. The Durbar Square is called the
          Hanumandhoka, the Monkey God’s Door in Nepal, named so after the
          Monkey God Hanuman. The area is also known as Basantpur, the place for
          celebrations and festivals. The UNESCO enlisted the Durbar Square area
          as the World Heritage Site in 1979. The massive pagoda-like structure
          in the center houses the tutelary deity of the Malla Kings. The palace
          building is a maze of stone-paved courtyards. Coronation ceremonies,
          until recently, were performed in the main courtyard, the Nyasal
          Chowk.
        </>
        <h3>Swayambhunath/Monkey Temple (World Heritage Site)</h3>
        <KSVImage
          src={require("./PackageAssets/Swayambhunath.png")}
          alt="cover"
        />
        The Swayambhunath Stupa crowns a hillock to the west of Kathmandu. A
        massive white dome surmounted by a 13-stage spire, the stupa is one of
        the most sacred Buddhist sites in Nepal. It is said to be 2000 years
        old. Its origins are linked to the creation of the Kathmandu Valley by
        Bodhisattva Manjushree. The site of the present-day city was originally
        a huge lake. Swayambhu was manifested in the lake as a brilliant light
        emanating from a lotus, and Manjushree let the water out by slashing a
        passage through the surrounding hills to facilitate paying homage to the
        deity, thus making the valley habitable.
        <h3>Patan Durbar Square (World Heritage Site)</h3>
        <KSVImage
          src={require("./PackageAssets/Patan Durbar Square.png")}
          alt="cover"
        />
        Most of the monuments in this square belong to the medieval Malla period
        between the 15th and 17th centuries AD. Important things to be seen in
        this area include the Golden Gate and the Golden Window of the old
        palace, the beautiful traditional metal craft, the famed Krishna temple
        with its 21 golden pinnacles, and the Royal Bath of Sundari Chowk, a
        perfect piece of classical stonework. The Royal Taleju temple and the
        Vishwa Narayan temple are fine examples of unique craftsmanship in wood.
        The temple of Bhimsen with its magnificent golden balcony overlooking
        the square outside includes many other shrines and sculptures scattered
        in and around the square.
      </DayContainer>
      <DayContainer>
        <DaysOfVisit>DAY 03: KATHMANDU TOUR</DaysOfVisit>
        Full-day sightseeing tour of Pashupatinath temple, Boudhanath, and
        Bhaktapur city. (Description of the tour highlights goes here) Overnight
        stay at hotel.
        <h3>Pashupatinath (World Heritage Site)</h3>
        <KSVImage
          src={require("./PackageAssets/Pashupatinath.png")}
          alt="cover"
        />
        Pashupatinath, considered one of the holiest shrines of all the Hindu
        temples, has remained the presiding deity of ruling Nepalese Royalty.
        Located on the banks of the Bagmati river, this two-tiered magnificent
        golden temple with four triple silver doorways is a unique example of
        Nepalese temple architecture. It is one of the largest Hindu temple
        complexes in South Asia with hundreds of Shiva lingams, shrines, and
        icons of various Hindu gods and goddesses inside.
        <h3>Boudhanath (World Heritage Site)</h3>
        <KSVImage src={require("./PackageAssets/Boudhanath.png")} alt="cover" />
        Boudhanath, one of the oldest and the biggest Buddhist monuments ever
        built in Nepal, is an imposing structure standing some 36 meters. The
        Stupa stands on the massive three-level mandala-style platforms
        surrounded by colorful private family houses. The basic feature of this
        great stupa is very much like that of Swayambhunath stupa except for its
        finial displaying. It is much bigger than Swayambhu stupa and lies on
        the valley floor, whereas the former one stands on the hilltop. This
        stupa is said to have been built in the 5th century A.D. The site is
        considered very much like Mecca for the Tibetan Buddhists, and every
        year tens of thousands of pilgrims from all over the Himalayan region
        visit the stupa.
        <h3>Bhaktapur Durbar Square (World Heritage Site)</h3>
        <KSVImage
          src={require("./PackageAssets/Bhaktapur Durbar Square.png")}
          alt="cover"
        />
        The city of Bhaktapur lies 14 km to the east of Kathmandu. Bhaktapur is
        popular for its religious and historical heritage and is also known as
        the "City of Culture" and "City of Devotees". Its Durbar Square is a
        symphony of art and architecture. The centerpiece is the 55-window
        palace overlooking the square, which is paved over with brick. The
        history of the palace dates back to the 12th century. Bhaktapur, also
        known as Bhadgaon, was the capital of the Kathmandu Valley once during
        medieval times. Today, it is a treasured home of medieval art and
        architecture. According to legend, this city was founded in 889 AD by
        the late King Anand ev, who belonged to the famed Lichhavi dynasty.
        Bhaktapur is the embodiment of the "Living heritage", with Hindus making
        up the majority of the population.
      </DayContainer>
      <DayContainer>
        <DaysOfVisit>DAY 04: DEPART KATHMANDU</DaysOfVisit>
        Our cheerful representative will drop you off at the international
        airport on time prior to your departure flight. The mystic land of the
        Himalayas holds many more secrets to be told. Come back again for
        another adventure visiting our spectacular cultural sites mountains.
      </DayContainer>
    </ContainerPac>
  );

  /*Change HTML Here */
};

export default GlimpsesOfKathmandu;

const ContainerPac = styled.div`
  width: 90vw;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background: #ecf1f0;
  box-sizing: border-box;

  @media (min-width: 768px) {
    margin-top: 80px;
    margin-bottom: 80px;
  }
`;

const DayContainer = styled.div`
  width: 90vw;
  max-width: 1100px;
  margin: 0 auto;
  padding: 10px;
  background: #ecf1f0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    margin-top: 80px;
    margin-bottom: 80px;
  }
`;

const ShortItinerary = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

const DItinerary = styled.div`
  color: blue;
  position: absolute;
  left: 38.5vw;
  margin-bottom: 30px;
`;

const DaysOfVisit = styled.div`
  color: red;
  font-size: 1.8rem;
  display: flex;
  flex-direction: column;
`;

const KSVImage = styled.img`
  width: 50vw;
  height: 50vh;
  object-fit: cover;
`;

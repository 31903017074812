import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import '../styles/review.css';

function Reviews() {
  return (
    <div className='cont-carousel'>
        {/* <img src={require('../assets/loation.png')} className="DecorationL" alt='' /> */}
        <div className="title">What People Say About Us</div>
            <Carousel className='carousel'>
                <Carousel.Item interval={1000}>
                    <img className="backgorund" src={require("../assets/reviewbg.png")} alt='pic'/>
                    <Carousel.Caption className="content">
                        <img src={require("../assets/person.png")} alt="avatar" className='avatar'/>
                    <p><i>I had an amazing experience with Brothers Travel Destinations! The team provided personalized service, making sure my trip was tailored to my preferences. Their expert knowledge of the destinations gave me insider tips, and the entire planning process was smooth and stress-free.

From arranging transportation to offering great recommendations, their attention to detail was impressive. I highly recommend them for anyone looking for a hassle-free, well-planned trip.

Rating: ★★★★★</i></p>
                        <span>- Joel Smith</span>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={500}>
                    <img className="backgorund" src={require("../assets/reviewbg.png")} alt='pic'/>
                    <Carousel.Caption className="content">
                        <img src={require("../assets/person.png")} alt="avatar" className='avatar'/>
                        <p><i>I booked a trip with Brothers Travel Destinations and couldn’t be happier! Their service was professional and personalized, with all the details taken care of, from accommodations to activities. They made the entire process easy, and I felt well-prepared for the trip.

Their team’s knowledge of the destinations really stood out, offering unique insights that enhanced my experience. I highly recommend Brothers Travel for anyone looking for a reliable, stress-free travel experience.

Rating: ★★★★★</i></p>
                        <span>- Mark Henry</span>
                    </Carousel.Caption>

        </Carousel.Item>
    </Carousel>
        {/* <img src={require('../assets/loation.png')} className="DecorationR" alt='' /> */}
    </div>
  )
}

export default Reviews;
